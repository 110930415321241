header {
    position: relative;
    z-index: 2;
}

.header-top {
    height: 176px;
    background-color: $color-white;

    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 90px;

        .site-identity {
            display: flex;
            align-items: center;
            z-index: 11;
        }

        .identity {
            &__title {
                position: relative;
                height: 191px;
                text-transform: uppercase;
                color: $color-text;
                margin: 0;
                transition: $duration;

                img {
                    position: relative;
                    z-index: 1;
                    top: 15px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: -200px;
                    right: -50px;
                    width: 1000px;
                    height: 300px;
                    border-radius: 50px;
                    background-color: $color-white;
                    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
                    transform: rotate(-3.84deg);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }
}

.home {
    .header {
        .header-top {
            .site-identity {
                .identity {
                    &__title {
                        &::after {
                            top: -30px;
                        }
                    }
                }
            }
        }
    }
}

// Sticky header
.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    height: auto;
    background-color: $color-white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    transition: all ease-in-out $duration;

    .header-top {
        display: flex;
        height: 80px;

        .container--identity {
            justify-content: unset;
            height: 80px;

            .identity {
                &__title {
                    img {
                        min-width: unset!important;
                    }
                }
            }
        }

        .site-identity {
            height: 50px;
        }

        .identity {

            &__title {
                display: flex;
                align-items: center;
                height: 50px;
                width: 35px;

                &::after {
                    content: none;
                }

                img {
                    border-radius: 50px;
                    background-color: $color-second;
                    transition: background-color $duration ease;
                }

                &:hover {

                    img {
                        background-color: $color-third;
                    }
                }
            }
        }

        #nav-main {
            height: 80px;
            margin: 0 auto 0 85px;

            .nav-main {

                &-link {
                    height: 80px;

                    svg {
                        bottom: 18px;
                    }
                }
            }

            .nav-main-item {
                .sub-menu {
                    ul {
                        padding-left: 15px;
                    }
                }
            }
        }

        .tools {
            position: absolute;
            left: 0;
            right: 0;
            order: 1;
            width: unset;
            height: 80px;
            background-color: $color-white;

            &__accessibility, &__profile {
                display: none;
            }

            &__search {
                margin: 0;

                svg {
                    width: 35px;
                    height: 35px;
                    margin-top: 0;
                    border-width: 0;
                    fill: $color-text--darker;
                    background-color: $color-second;
                }

                &--disabled {
                    pointer-events: none;
                    opacity: .2;
                }

                &:hover {

                    svg {
                        fill: $color-black;
                        background-color: $color-third;
                    }
                }

                span {
                    display: none;
                }
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    width: 100%;
    height: 68px;
    background-color: $color-main;

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        height: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
        background: $color-bg--transparent;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 25px;
        padding: 0;
        background-color: $color-bg--transparent;
        border: 0;
        
        svg {
            fill: $color-second;
            border-color: $color-second;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;

            svg {
                fill: $color-text--darker;
                border-color: $color-text--darker;
            }
        }
    }

    &__translate {
        svg {
            width: 23px;
            height: 23px;
        }
    }

    &__accessibility {
        svg {
            width: 23px;
            height: 23px;
            margin-right: 10px;
            border: 2px solid $color-black;
            border-radius: 50px;
        }
    }

    &__profile {
        svg {
            width: 23px;
            height: 23px;
            margin-right: 10px;
        }
    }

    &__search {
        svg {
            width: 23px;
            height: 23px;
            margin-right: 10px;
            border: 2px solid $color-black;
            border-radius: 50px;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

    }
}

.tools-view {
    position: absolute;
    top: 68px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 300px;
    transform: translateX(280px);
    z-index: 20;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin: 0;
            padding: 10px 15px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-text--darker;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        color: $color-text--darker;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-text--darker;
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: block;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-top: 0;
        margin-left: 0;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .header-top {

        .container--identity {

            .identity {

                &__title {
                    height: 147px;

                    img {
                        height: 147px;
                        width: 130px;
                        min-width: 130px;
                        top: 0;
                    }
                }
            }

            .site-identity {
                .identity__title::after {
                    top: -210px;
                    right: -40px;
                }
            }
        }
    }

    .home {
        .header {
            .header-top {
                .site-identity {
                    .identity__title::after {
                        top: -80px;
                    }
                }
            }
        }
    }


    .header--fixed {
        .header-top {
            .container--identity {
                .identity__title {
                    height: 35px;

                    img {
                        height: 35px;
                        width: 35px;
                    }
                }
            }

            #nav-main {
                .nav-main-item {
                    .sub-menu {
                        ul {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    .tools-view {
        transform: translateX(180px);
    }


}


// 960
@media screen and (max-width: $medium) {

    .header-top {

        .container--identity {
            .site-identity {
                .identity__title::after {
                    top: -215px;
                    right: -25px;
                }
            }
        }
    }

    .home {
        .header {
            .header-top {
                .site-identity {
                    .identity__title::after {
                        top: -85px;
                        right: -25px;
                    }
                }
            }
        }
    }

    #nav-main {

        .nav-main_icon {
            width: 100%;
            height: 104px;
            padding: 25px 0;
            font-size: $font-size--large;
            font-weight: $font-weight-bold;
            text-transform: lowercase;
            justify-content: center;

            .navicon {
                width: 30px;
                height: 4px;
                top: 4px;

                &:before {
                    top: -8px;
                }

                &:after {
                    top: 8px;
                }
            }
        }

        .nav-main-item {
            .nav-main-link {
                color: $color-text--darker;

                svg {
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }

                &:hover {
                    
                    svg {
                        fill: $color-text--darker;
                    };
                }
            }

            .sub-menu {
                ul {
                    padding-left: 0;
                    column-count: 1;
                }
            }
        }
    }

    .tools {
        &__accessibility, &__profile, &__search {

            span {
                font-size: $font-size--text-small;
            }
        }
        .tool {
            margin: 0 10px;
        }

        &-view {
            transform: translateX(85px);
        }
    }
    
    .header--fixed {
        .header-top {
            .container--identity {
                .identity__title {
                    height: 35px;

                    img {
                        height: 35px;
                        width: 35px;
                    }
                }
            }

            #nav-main {
                width: 530px;
                margin-right: 45px;

                .nav-main_icon {
                    width: 530px;
                }
            }
        }
    }
  
}


// 640
@media screen and (max-width: $small) {


    #nav-main {
        z-index: 11;
        position: initial;

        .nav-main_icon {
            font-size: 0;

            .navicon {
                margin: 0 auto;
            }
        }

        .menu__nav__list {
            top: 150px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .tools {
        &__accessibility, &__profile, &__search {
            margin: 0 5px!important;

            span {
                display: none;
            }
        } 
    }

    .header--fixed .header-top #nav-main {
        width: 200px;
        margin-right: 55px;
    }

    .privacy__container {
        display: block;
    }

    .privacy__button {
        margin-top: 15px;
    }

}
