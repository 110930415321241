.footer {
    height: 336px;
    margin-top: 70px;

    &__background {
        height: 213px;
        background-color: $color-main;
    }

    &__infos {
        position: relative;
        padding: 60px 0 70px 0;
        height: 213px;

        &__carte {
            position: absolute;
            top: -75px;
            width: 370px;

            &:after {
                content: "";
                position: absolute;
                top: -50px;
                right: -50px;
                width: 1000px;
                height: 850px;
                border-radius: 50px;
                background-color: $color-white;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
                transform: rotate(-3.84deg);
            }
        }

        &__title {
            font-size: $font-size--extramedium;
            font-weight: $font-weight-bold;
            margin: 40px 0 15px 0;
        }

        &__subtitle {
            font-weight: $font-weight-bold;
            line-height: 1.25;
        }

        &__text {
            margin: 15px 0 45px 0;
            line-height: 1.25;
        }

        &__logo, &__coordonnees {
            position: relative;
            z-index: 1;
        }

        &__logo {
            height: 71px;
            margin-top: 10px;
        }

        &__phone {

            margin-bottom: 20px;

            svg {
                border: 2px solid $color-second;
                border-radius: 50px;
            }
        }
        
        &__phone, &__contact {

            display: flex;
            align-items: center;
            height: 22px;
            font-weight: $font-weight-semibold;

            svg {
                fill: $color-second;
                margin-right: 10px;
                width: 20px;
                height: 20px;
                transition: margin-right $duration ease;
            }

            &:hover {

                svg {
                    margin-right: 15px;
                }
            }
        }

        &__horaires {
            width: 470px;
            margin: -15px auto 0 440px;

            .footer__infos {
                
                &__title {
                    margin: 0;
                }

                &__text {
                    margin: 15px 0 0 0;
                }
            }
        }
    }

    &__buttons {
        position: absolute;
        bottom: -30px;
        right: 0;
        left: 0;
        margin: 0 auto 0 425px;

        .footer__button {
            border-width: 0;
            margin: 0 15px;
            text-transform: none;
            font-size: $font-size--6;
            box-shadow: $btn-shadow;
            background-color: $color-white;

            &:hover {
                background-color: $color-second;
                box-shadow: none;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__menu {
        padding: 65px 0 40px 0;
        width: 727px;
        background: $color-white;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0!important;
            }
            a {

                font-size : $font-size--text-small;

                &:hover {
                        color: $color-second;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin:  auto 0;
                width: 2px;
                height: 12px;
                background: $color-third;
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    
    .footer {
        height: 370px;

        &__infos {

            &__title {
                font-size: $font-size--text-medium;
            }

            &__carte {
                width: 295px;

                &:after {
                    right: -40px;
                }
            }

            &__horaires {
                width: 580px;
                transform: unset;
                margin: 0 0 0 auto;
            }
        }

        &__buttons {
            width: 580px;
            transform: unset;
            margin:  0 0 0 auto;
            bottom: -20px;

            .footer__button {
                margin-left: 0;
                padding: 15px;
            }
        }

        &__menu {
            width: 580px;
            padding: 55px 0 40px;

            li {
                padding: 0 30px 5px;

                
                &:first-child {
                    padding-left: 30px;
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .footer {
        height: unset;

        &__background {
            height: 310px;
        }

        &__infos {

            &__horaires {
                width: 240px;
                margin: 0 0 0 auto;
                transform: unset;

                .footer__infos__text {
                    margin-bottom: 30px;
                }
            }

            &__carte {
                width: 320px;

                &:after {
                    height: 440px;
                    top: -20px;
                    right: -20px;
                }
            }
        }

        &__buttons {
            position: initial;
            width: 240px;
            margin: 0 0 0 auto;
            transform: unset;

            .footer__button {
                width: 240px;
                margin: 0;
                padding: 15px;

                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        &__menu {
            width: 100%;
            margin-top: 75px;

            li {
                padding: 0 20px 5px;

                &:first-child {
                    padding-left: 20px;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        margin-top: 520px;

        &__background {
            height: 280px;
        }

        &__infos {
            height: 310px;

            &__carte {
                z-index: -1;
                top: -400px;
                width: 100%;

                &:after {
                    width: 800px;
                    height: 500px;
                    top: -60px;
                    right: -200px;
                }
            }

            &__horaires {
                width: 100%;
            }
        }

        &__buttons {
            width: 100%;
            position: absolute;
            bottom: -60px;

            .footer__button {
                width: 100%;
            }
        }

        &__menu {
            margin-top: 55px;

            li {
                padding: 0 20px;
                margin: 8px 0;

                
            }
        }
    }
}
