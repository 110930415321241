.cover {
    position: relative;
    display: flex;
    background-color: $color-second;

    &__image-wrapper {
        width: 35vw;
        height: auto;
        overflow: hidden;
        background: $color-bg--image;

        img {
            width: 35vw;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        display: flex;
        align-items: center;
        width: 700px;
        margin-left: 50px;
        padding: 30px 0;
    }

    &__title {
        color: $color-text--darker;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text--darker;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500 to 1200
@media screen and (min-width: 1200px) and (max-width: 1500px) {

    .cover {

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 350px;
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .cover {

        &__informations {
            width: 570px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {

        &__informations {
            width: 
            375px;
        }

        &__image-wrapper {
            height: auto;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        display: block;

        &__informations {
            width: 100%;
            margin: 0;
        }

        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: auto;
            width: 100%;
            background-color: transparent;

            & img {
                width: 100%;
            }
        }
    }

}
