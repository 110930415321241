.breadcrumb {
    background-color: $color-main;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        position: relative;
        align-items: center;
        color: $color-text--darker;
        font-weight: $font-weight-semibold;
        padding: 0 10px;
        margin: 0 21px;

        &:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            right: -35px;
            background-size: contain!important;
            background-repeat: no-repeat!important;
            background: url('/wp-content/themes/smictom-pays-de-vilaine/assets/src/images/sprite-images/Pictos/signaler_un_pb_fleche.svg');
        }

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;

            &:after {
                content: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: none;
            /*display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
            box-shadow: 0px -1px 0 rgb(32 32 34 / 10%);*/
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
