.front-page {
    position: relative;
    overflow: hidden;
}

.home {

    &__title {
        position: relative;
        z-index: 1;
        font-size: $font-size--large;
        font-weight: $font-weight-bold;
        line-height: 55px;
        color: $color-text;
        border-width: 0;

        span {
            font-size: $font-size--extralarge;
            font-weight: $font-weight-extrabold;
            line-height: 60px;
            text-transform: capitalize;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
}


//======================================================================================================
// Posts
//======================================================================================================
.post {
    height: 537px;

    &__content {
        height: 537px;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;

            .post__container {
                position: relative;
                height: 537px;
            }
        }

        & img {
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }
    }

    &__container {
        position: relative;
    }

    &__info {
        position: absolute;
        bottom: 0;
        right: 30px;
        width: 270px;
    
        &::after {
            content: "";
            position: absolute;
            top: -50px;
            left: -50px;
            width: 1000px;
            height: 415px;
            border-radius: 50px;
            background-color: $color-second;
            transform: rotate(-3.84deg);
            opacity: 0.9;
        }

        &__title {
            position: relative;
            z-index: 1;
            margin-bottom: 80px;
            color: $color-text--darker;
            font-size: $font-size--extramedium;
        }
    }

    &__title {
        position: relative;
        width: 155px;
        margin: 0;
        padding: 0;
        color: $color-text--darker;
    }

    &__buttons {
        position: absolute;
        z-index: 25;
        bottom: 20px;
        left: 0;
        right: 30px;
        text-align: right;
    }

    &__button {
        margin-right: 85px;
        padding: 0;
        background: transparent;
        border-width: 0;

        svg {
            width: 20px;
            height: 20px;
            border: 2px solid $color-white;
            border-radius: 50px;
            margin-right: 10px;
        }

        &:hover, &:focus, &:active {
            background: transparent;
            margin-right: 80px;

            svg {
                fill: $color-white;
                margin-right: 15px;
            }
        }
    }

    &__navigation {

        position: absolute;
        bottom: 200px;
        left: calc(50% + 480px);
        margin: 0 auto;
        width: 90px;
        height: 35px;

        .swiper-button {

            &-prev, &-next {

                position: absolute;
                top: 22px;
                height: 35px;
                width: 35px;
                background-color: $color-white;
                border-radius: 50px;

                svg {
                    height: 35px;
                    fill: transparent;

                    path[stroke="#111111"] {
                        stroke: $color-black;
                    }
                }

                &:hover {

                    background-color: $color-black;
                    
                    svg {

                        path[stroke="#111111"] {
                            stroke: $color-white;
                        }
                    }
                }

                &::after {
                    content: none;
                }
            }

            &-prev {

                left: 0;
            }

            &-next {

                right: 0;
            }
        }
    }
}


//======================================================================================================
// Profil Menu
//======================================================================================================
.profilmenu {

    &__title {
        margin: 0;
        line-height: 4.4rem;
        font-size: $font-size--extralarge;
        font-weight: $font-weight-extrabold;
    }

    &__pictos {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    &__container {
        width: 470px;
        margin-top: 110px;
    }

    &__intro {
        margin-bottom: 25px;
        font-size: $font-size--extramedium;
        line-height: 1.2;
        font-weight: $font-weight-bold;
    }

    &__select {
        display: flex;
        position: relative;
        width: 100%;
        height: 28px;
        align-items: baseline;
        justify-content: space-between;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;
            background-color: $color-second;
        }

        svg {
            width: 10px;
            height: 10px;
            fill: $color-second;
        }

        &:hover, &:focus, &:active {
            background-color: transparent;

            svg {
                transform: rotate(180deg);
            }
        }
    }
}

#nav-profilmenu {
    position: relative;
    display: inline-block;
    width: 100%;

    .profilmenu {

        &__select {
            border: none;
            cursor: pointer;            
        }

        &__list {
            display: none;
            position: absolute;
            background-color: $color-second;
            width: 100%;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;

            a {
                color: $color-text--darker;
                font-size: $font-size--text;
                padding: 10px 15px;
                text-decoration: none;
                display: block;
            
                &:hover {
                    background-color: $color-main;
                    padding-left: 30px;
                }
            }

            &.show-profil {
        
                    display: block;
            }
        }
    }
}



//======================================================================================================
// Quick Access
//======================================================================================================
.access {

    &__container {
        width: 600px;
        margin-top: 115px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__item {
            width: 180px;
            height: 120px;

            &:nth-child(-n+3) {
                margin-bottom: 30px;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 180px;
            height: 120px;
            border: 2px solid $color-main;
            border-radius: 5px;
            background-color: $color-main;
            overflow: hidden;

            svg {
                position: absolute;
                fill: $color-second;
                width: 180px;
                height: 120px;
                top: 20px;
                right: -60px;
            }

            span {
                font-size: $font-size--text-medium;
                font-weight: $font-weight-bold;
                color: $color-text--darker;
                line-height: 1.2;
                padding: 0 12px;
                text-align: center;
            }

            &:hover {
                background-color: $color-white;
                border-color: $color-third;

                svg {
                    fill: $color-third;
                }
            }
        }
    }
}



//======================================================================================================
// Problem
//======================================================================================================
.problem {

    &__container {
        position: relative;
        margin-top: 265px;

        &::after {
            content: "";
            position: absolute;
            top: -120px;
            right: -50px;
            width: 1000px;
            height: 415px;
            border-radius: 50px;
            background-color: $color-main;
            transform: rotate(-3.84deg);
            z-index: -1;
        }
    }

    &__title {
        position: relative;
        margin: 0;
        color: $color-text;

        svg {
            position: absolute;
            left: 0;
            top: -67px;
        }
    }

    &__intro {
        width: 524px;
        margin: 10px 0 25px 0;
    }

    &__links {

        &__item {
            margin-bottom: 20px;
        }

        &__link {

            display: flex;
            align-items: center;

            span {
                font-weight: $font-weight-semibold;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                fill: $color-second;
                border: 2px solid $color-second;
                border-radius: 50px;
                transition: margin-right $duration ease;
            }

            &:hover {

                svg {
                    margin-right: 15px;
                }
            }
        }
    }
}



//======================================================================================================
// Materiel
//======================================================================================================
.materiel {

    &__container {

        position: relative;
        width: 500px;
        margin-top: 265px;
    }

    &__title {
        position: relative;
        margin-top: 0;
        margin-bottom: 50px;
        color: $color-text;

        &::after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 0;
            height: 5px;
            width: 325px;
            background-color: $color-third;
            z-index: -1;
        }
    }

    &__image {
        position: absolute;
        width: 290px;
        height: 340px;
        top: -80px;
        right: -90px;
    }

    &__links {

        &__link {
            
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            
            span {
                width: 235px;
                font-weight: $font-weight-semibold;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                fill: $color-third;
                border: 2px solid $color-third;
                border-radius: 50px;
                transition: margin-right $duration ease;
            }

            &:hover {

                svg {

                    margin-right: 15px;
                }
            }
        }
    }
}



//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__container {
        width: 200px;
        margin-top: 320px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 200px;
        height: 183px;
        border: 2px solid $color-main;
        border-radius: 5px;
        background-color: $color-main;
        overflow: hidden;

        svg {
            position: absolute;
            fill: $color-second;
            width: 150px;
            height: 140px;
            top: 30px;
            right: -20px;
        }

        span {
            font-size: $font-size--text-medium;
            font-weight: $font-weight-bold;
            color: $color-text--darker;
            line-height: 1.2;
            padding: 0 12px;
            text-align: center;
        }

        &:hover {
            background-color: $color-white;
            border-color: $color-third;

            svg {
                fill: $color-third;
            }
        }
    }
}



//======================================================================================================
// Events
//======================================================================================================
.events {

    &__container {
        position: relative;
        width: 870px;
        margin-top: 175px;
        margin-bottom: 80px;

        min-height: 400px;

        .swiper-container {
            border-radius: 5px;
            box-shadow: 0 0 15px rgba(0,0,0,.15);
        }
    }

    &__title {
        padding: 0;
        margin: 0 0 25px 0;

        span {
            display: block;
        }
    }

    &__content {
        display: flex;
        width: 200px;
        height: 183px;

        img {
            width: 200px;
            height: 183px;
            transform: scale(1);
            transition: transform $duration ease-in-out;
        }

        &--notimg {
            min-width: 200px;
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }
    }

    &__link {
        display: flex;

        &:hover {
            .events__content img {
                transform: scale(1.1);
                transition: transform $duration ease-in-out;
            }
        }

    }

    &__infos {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: $color-white;

        &__title {
            padding: 0 45px 0 110px;
            color: $color-text;
        }
    }

    &__date {
        position: absolute;
        top: 9px;
        left: 185px;
        padding: 15px 20px;
        text-align: center;
        border-radius: 5px;
        background-color: $color-third;

        p {
            height: 55px;
            text-align: center;
        }

        svg {
            width: 10px;
            height: 10px;
        }

        &--same {
            top: 50px;
        }

        &__day {
            display: block;
            height: 30px;
            line-height: 0.85;
            font-size: $font-size--slarger;
            font-weight: $font-weight-extrabold;
        }

        &__month {
            text-transform: uppercase;
            font-size: $font-size--text-medium;
            font-weight: $font-weight-bold;
        }
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
    }

    &__button {
            
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        width: 220px;
        font-weight: $font-weight-semibold;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: $color-third;
            border: 2px solid $color-third;
            border-radius: 50px;
            transition: margin-right $duration ease;
        }

        &:hover {

            svg {

                margin-right: 15px;
            }
        }
    }

    &__button:first-child {
        width: 190px;
        margin-right: 25px;
    }
    
    &__navigation {

        position: absolute;
        top: 70px;
        left: 270px;
        width: 90px;
        height: 35px;

        .swiper-button {

            &-prev, &-next {

                position: absolute;
                top: 22px;
                height: 35px;
                width: 35px;
                background-color: $color-third;
                border-radius: 50px;

                svg {
                    height: 35px;
                    fill: transparent;

                    path[stroke="#111111"] {
                        stroke: $color-black;
                    }
                }

                &:hover {

                    background-color: $color-black;
                    
                    svg {

                        path[stroke="#111111"] {
                            stroke: $color-white;
                        }
                    }
                }

                &::after {
                    content: none;
                }
            }

            &-prev {

                left: 0;
            }

            &-next {

                right: 0;
            }
        }
    }
}



//======================================================================================================
// Territoire
//======================================================================================================
.territoire {

    &__section {
        background-color: $color-main;
    }

    &__container {
        display: flex;
        justify-content: space-between;

        &__map {
            position: relative;
            width: 520px;
            height: 752px;
            padding-top: 50px;

            &__city {
                display: flex;
                align-items: center;
            }
        }

        &__admin-area {
            width: 500px;
            padding-top: 65px;
        }
    }

    &__map {
        position: relative;

        &__svg {
            position: relative;
        }

        &__list {
            position: absolute;
            top: 0;
        }

        &__item {
            position: absolute;
            top: 0;
            left: 0;

            // Guichen
            &:nth-child(1) {
                top: 30px;
                left: 250px;
            }

            // Val d'Anast
            &:nth-child(2) {
                top: 110px;    
                left: 90px;
            }

            // Guipry-Messac
            &:nth-child(3) {
                top: 180px;
                left: 220px;
            }

            // Pipriac
            &:nth-child(4) {
                top: 220px;
                left: 140px;
            }

            // Sixt-sur-Aff
            &:nth-child(5) {
                top: 260px;
                left: 30px;
            }

            // Bain-de-Bretagne
            &:nth-child(6) {
                top: 210px;
                left: 320px;
            }

            // Le Grand Fougeray
            &:nth-child(7) {
                top: 320px;
                left: 290px;
            }

            // Le Petit Fougeray
            &:nth-child(8) {
                top: 90px;
                left: 380px;
            }
        }

        &__city {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 62px;

            span {
                display: none;
                opacity: 0;
                position: absolute;
                width: max-content;
                bottom: 65px;
                margin: 0 auto;
                border-radius: 5px;
                font-weight: $font-weight-bold;
                font-size: $font-size--text;
                text-transform: uppercase;
                background-color: $color-second;
                padding: 10px 15px;
                margin-bottom: 10px;
            }

            &:hover {

                svg {
                    path.territoire__path {
                        fill: $color-second;
                        stroke: $color-second;
                        transition-property: fill, stroke;
                        transition: $duration ease-in-out;
                    }
                }

                span {
                    display: block;
                    opacity: 1;
                    transition: opacity $duration ease-in-out;
                }
            }
        }
    }

    &__btns {
        display: flex;
        flex-direction: column;
    }

    &__btn {
        display: flex;
        align-items: center;
        font-weight: $font-weight-semibold;
        margin-bottom: 17px;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: $color-second;
        }
    }

    &__btn-service {
        position: absolute;
        text-align: center;
        margin: 0;
        width: 100%;
        bottom: -25px;
        border-width: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        background-color: $color-white;

        &:hover, &:focus {

            background-color: $color-second;
            box-shadow: none;
        }
    }

    &__title {
        padding: 0;
        margin: 0 0 45px 0;

        span {
            display: block;
        }
    }

    &__admin {

        &-blocs {
            position: relative;
            columns: 2;
            column-gap: 90px;

            &::after {
                content: "";
                position: absolute;
                bottom: -20px;
                left: 225px;
                width: 1000px;
                height: 750px;
                border-radius: 50px;
                background-color: $color-second;
                transform: rotate(-3.84deg);
            }
        }    
    
        &-area {

            position: relative;
            width: 180px;
            height: 132px;
            z-index: 1;

            &__number {
                font-size: $font-size--large;
                font-weight: $font-weight-black;
                color: $color-white;
            }

            &__title {
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                color: $color-text--darker;
                line-height: 20px;
            }

            &__info {
                font-weight: $font-weight-semibold;
                color: $color-text--darker;
                line-height: 25px;
            }

            &:nth-child(-n+3) {
                .territoire__admin-area {
                
                    &__number {
                        color: $color-second;
                    }

                    &__title, &__info {
                        color: $color-text;
                    }
                }
            }
        }
    }
}

.gestion {

    &__container {
        margin-top: 100px;
    }

    &__title {
        position: relative;

        svg {
            position: absolute;
            left: 0;
            top: -67px;
        }
    }

    &__dropdown {
        width: auto;

        .select2 {

            height: 35px;
            background-color: transparent;
            border-radius: unset;
            border-bottom: 2px solid $color-second;
            box-shadow: unset;
            
            &:after {
                width: 12px;
                right: 0;
                margin-right: 0;
                height: 35px;
                transform: rotate(0deg);
                transition: .45s;
                background: transparent url(/wp-content/themes/smictom-pays-de-vilaine/assets/src/images/sprite-images/Pictos/chevron-dropdown-list.svg) no-repeat 50%;
            }

            &:hover, &:focus {
                &:after {
                    transform: rotate(180deg);
                    background: transparent url(/wp-content/themes/smictom-pays-de-vilaine/assets/src/images/sprite-images/Pictos/chevron-dropdown-list.svg) no-repeat 50%; 
                }
            }

            &.select2-container--open {
                &:after {
                    transform: rotate(180deg);
                    background: transparent url(/wp-content/themes/smictom-pays-de-vilaine/assets/src/images/sprite-images/Pictos/chevron-dropdown-list.svg) no-repeat 50%; 
                }
            }

            &-selection__placeholder {
                color: $color-text!important;
            }

            &-selection__rendered {
                padding-left: 0!important;
                padding-right: 0!important;
            }
        }

        .dropdown-submit {
            display: block;
            position: relative;
            right: unset;
            top: unset;
            bottom: unset;
            margin: auto 0 auto 15px;
            height: 35px;
            background-color: $color-second;

            &:hover {
                background-color: $color-third;
            }
        }
    }
}



//======================================================================================================
// Partenaire
//======================================================================================================
.partenaire {

    &__area {
        padding-top: 70px;
    }

    &__title {
        width: 372px;
        margin: 0 60px 0 0;
    }

    &__container {
        display: flex;
    }

    &__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
        width: 760px;

        &__item {
            width: 170px;
            height: 100px;

            &:nth-child(-n+4) {
                margin-bottom: 40px;
            }
        }
    }

    &__link {
        display: inline-block;
        width: 170px;
        height: 100px;

        &__image {
            transform: scale(1);
            transition: transform $duration ease-in-out;
        
            &:hover, &:focus {
                transform: scale(0.9);
                transition: transform $duration ease-in-out;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .post {

        &__title {
            height: auto;
            margin-bottom: 25px;
        }
        
        &__content {

            img {
                height: 430px;
            }

            &--notimg {
                .post__container {
                    height: 430px;
                }
            }
        }

        &__navigation {
            left: calc(50% + 370px);
        }
    }

    .post, .post__content {
        height: 430px;
    }

    .profilmenu {

        &__container {
            width: 380px;
        }

        &__intro {
            font-size: $font-size--text-medium;
        }

        &__pictos {
            margin-bottom: 0;
        }
    }

    .access {

        &__container {
            width: 481px;
        }

        &__list{

            &__item, &__link {

                width: 147px;
                height: 110px;
            }

            &__link {

                span {
                    font-size: $font-size--5;
                } 
            }

            &__item {

                &:nth-child(-n+3) {
                    margin-bottom: 20px;
                }
            }
        }
    }


    .problem {

        &__container{
            
            &:after {
                top: -100px;
                height: 440px;
            }
        }

        &__intro {
            width: 460px;
        }
    }

    .materiel {

        &__container {
            width: 400px;
        }

        &__image {
            right: -170px;
        }
    }

    .kiosque {

        &__container {
            width: 160px;
            height: 146px;
        }

        &__link {
            width: 160px;
            height: 146px;
        }
    }

    .events {

        &__container {
            width: 700px;
            min-height: 360px;
        }

        &__content, &__content img {
            width: 160px;
            height: 146px;
        }

        &__content--notimg {
            min-width: 160px;
        }

        &__date {
            top: 0px;
            left: 115px;
            height: 111px;
            padding: 20px 20px 15px 20px;

            &--same {
                height: unset;
                top: 30px;
            }
            
            p {
                height: auto;
            }

            &__day {
                font-size: $font-size--extramedium;
                height: auto;
            }

            &__month {
                font-size: $font-size--text;
                height: auto;
            }
        }

        &__infos {

            &__title {
                font-size: $font-size--text-medium;
                padding: 0 20px 0 50px;
            }
        }

        &__button {
            width: 230px;

            svg {
                margin-right: 5px;
            }

            &:first-child {
                width: 210px;
                margin-right: 15px;
            }

            &:hover {

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .territoire {

        &__container {

            &__map {
                width: 440px;
            }

            &__admin-area {
                width: 430px;
            }
        }

        &__map {

            &__svg svg {
                width: 440px;
            }

            &__list {
                
                li a svg {
                    width: 40px;
                    height: 50px;
                }
            }

            &__city {
                height: 50px;

                span {
                    font-size: $font-size--text-small;
                }
            }

            &__item {

                &:first-child {
                    top: 55px;
                    left: 205px;
                }

                &:nth-child(2) {
                    top: 130px;
                    left: 70px;
                }

                &:nth-child(3) {
                    top: 190px;
                    left: 185px;
                }

                &:nth-child(4) {
                    top: 215px;
                    left: 120px;
                }

                &:nth-child(5) {
                    top: 250px;
                    left: 30px;
                }

                &:nth-child(6) {
                    top: 210px;
                    left: 270px;
                }

                &:nth-child(7) {
                    top: 310px;
                    left: 245px;
                }

                &:nth-child(8) {
                    top: 100px;
                    left: 315px;
                }
            }
        }

        &__btn-service {
            width: 400px;
        }
    }

    .partenaire {

        &__links {

            &__item {
                width: 120px;
                height: 70px;
            }
        }

        &__link, &__link__image {
            width: 120px;
            height: 70px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .home {

        &__container {
            display: block;
        }

        &__kiosage {
            display: flex;
        }
    }

    .post {
        
        &__info {

            &__title {
                font-size: $font-size--text-medium;
            }
        }

        &__navigation {
            left: calc(50% + 215px);
            bottom: 170px;
        }
    }

    .profilmenu {

            &__container {
                width: 100%;
            }

            &__intro {
                font-size: $font-size--text-medium;
            }
    }

    .access {

        &__container {
            width: 100%;
            margin-top: 70px;
        }

        &__list {

            &__link, &__item {
                width: 190px;
                height: 142px;
            }
        }
    }

    .materiel {

        &__container {
            margin-top: 210px;
        }
    }

    .events {

        &__container {
            width: 430px;
        }
    }

    .territoire {

        &__section {
            padding-bottom: 20px;
        }

        &__container {
            display: block;

            &__map {
                width: 100%;
            }

            &__admin-area {
                width: 100%;
            }
        }

        &__map {

            &__item {
                position: relative;
                padding: 10px 0;

                &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                    top: unset;
                    left: 0;
                }
            }

            &__city {

                svg {
                    margin-right: 15px;
                }

                span {
                    display: block;
                    opacity: 1;
                    position: initial;
                    order: 1;
                    margin: 0 auto 0 0;
                    background-color: $color-third;
                    border: 2px solid $color-white;
                }
            }

            &__svg svg {
                width: unset;
            }

            &__list {
                margin-top: 50px;
                columns: 2;

                li a svg {
                    width: 40px;
                }
            }
        }

        &__btn-service {
            z-index: 1;
            width: 100%;
        }

        &__admin-blocs {

            &::after {
                left: 250px;
                height: 500px;
            }
        }
    }

    #mapSvg {
        opacity: .5;
    }

    .partenaire {

        &__area {
            margin-bottom: 80px;
        }

        &__container {
            display: block;
        }

        &__links {
            width: 640px;

            &__item {
                width: 132px;
                height: 78px;
            }
        }

        &__link, &__link__image {
            width: 132px;
            height: 78px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home {

        &__title {

            span {
                font-size: $font-size--1-5;
            }
        }
    }

    .post {
        height: 570px;

        &__container {
            height: 570px;
        }

        &__navigation {
            left: 50%;
            bottom: 190px;
        }

        &__content {
            height: 570px;
            width: 100%;
            background-color: $color-second;

            img {
                height: 267px;
                width: 100%;
                object-fit: cover;
            }

            &--notimg {
                position: relative;
                background: none;
                background-color: $color-second;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    height: 267px;
                    width: 640px;
                    background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat #989898;
                    background-position: 50%;
                }

                .post__info {
                    padding-top: 267px;
                }
            }
        }

        &__info {
            position: initial;

            &:after {
                content: none;
            }
        }

        &__buttons {
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: left;
        }
    }

    .profilmenu {

        &__container {
            margin-top: 80px;
        }
    }

    .access {

        &__list {

            &__link, &__item {
                width: 140px;
                height: 105px;
            }
        }
    }

    .problem {

        &__container {
            margin-top: 195px;

            &:after {
                top: -80px;
            }
        }

        &__intro {
            width: 300px;
        }

        &__links__link svg {
            min-width: 20px;
        }
    }

    .materiel {

        &__container {
            margin-top: 160px;
        }
    }

    // Section Kiosque + Agenda
    .home__kiosage {
        display: block;
    }

    .kiosque {

        &__container {
            margin-top: 135px;
            width: 100%;
        }

        &__link {
            width: 100%;
        }
    }

    .events {

        &__container {
            margin-top: 50px;
        }

        &__title {
            margin-bottom: 85px;
        }

        &__navigation {
            top: 130px;
            left: 10px;
        }

        &__link {
            display: block;
        }

        &__content, &__content img {
            width: 300px;
            height: 270px;
        }

        &__infos {

            &__title {
                padding: 45px 25px 25px;
                margin: 0;
            }
        }

        &__date, &__date--same {
            top: 220px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &__date {
            height: unset;
            max-height: unset;
            width: 146px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        &__date--same {
            width: 36px;
        }

        &__buttons {
            display: block;
        }
    }

    .territoire {

        &__section {
            padding-bottom: 40px;
        }

        &__container {

            &__map {
                height: auto;
            }
        }

        &__map {

            &__svg {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 540px;

                #mapSvg {
                    min-width: 521px;
                }
            }

            &__list {
                margin-top: 0;
                column-count: 1;
            }

            &__item {
                padding: 5px 0;
            }
        }

        &__btn-service {
            position: relative;
            bottom: -20px;
        }

        &__admin-area {
            width: 140px;

            &:nth-child(-n+3) {

                .territoire__admin-area__number {
                    color: $color-white;
                }

                .territoire__admin-area {

                    &__info, &__title {
                        color: $color-text--darker;
                    }
                }
            }
        }

        &__admin-blocs {

            &:after {
                left: -60px;
            }
            column-gap: 30px;
        }

    }

    .gestion {

        &__container {
            margin-top: 170px;
        }
    }

    .partenaire {

        &__title, &__links {
            width: 100%;
        }

        &__area {
            margin-bottom: 0;
        }
    }
}
