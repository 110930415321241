#nav-main {
    position: inherit;
    z-index: 10;
    height: 108px;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
                &:after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 108px;
            padding: 0 60px 0 30px;
            color: $color-text--darker;
            font-size: $font-size--text-medium;
            font-weight: $font-weight-bold;
            line-height: 24px;

            span {
                font-size: $font-size--text ;
                font-weight: $font-weight-semibold;
                line-height: 24px;
            }

            svg {
                position: absolute;
                bottom: 35px;
                right: 35px;
                width: 12px;
                height: 12px;
                z-index: 1;
                fill: $color-second;
                text-align: center;
            }

            &:hover {
                background: $color-second;

                svg {
                    fill: $color-white;
                    transform: rotate(180deg);
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-main--rgba;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 2;
                column-gap: 30px;
                padding-left: 270px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid $color-second--rgba;
                    color: $color-text;
                    font-size: $font-size--5;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 2px;
                        background: $color-second;
                        transition: all $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-second;
                        transition: all $duration;
                    }

                    &:hover {

                        padding: 15px 30px 15px 15px;
                        background-color: $color-second;

                        &::after {
                            color: $color-white;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 30px;
                    border-bottom: 0;
                    font-size: $font-size--text;
                    font-weight: $font-weight-normal;
                    text-transform: initial;
                    line-height: initial;

                    &:hover {
                        padding-left: 45px;
                    }

                    &::after {
                        left: 10px;
                    }

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-text--darker;

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: -1px;
        width: 20px;
        margin-right: 15px;
        background: $color-second;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-second;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        width: 736px;

        .menu {

            &__nav {

                &__list {
                    justify-content: space-between;
                }
            }
        }

        .nav-main-item {
            .nav-main-link {
                padding: 0 30px 0 10px;
                font-size: $font-size--5;

                svg {
                    right: 10px;
                }
            }

            .sub-menu {

                ul {
                    padding-left: 230px;
                }
            }
        }
    }
    
    .tools-view {
        transform: translateX(225px);
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 75px;
            width: 100%;
            margin: auto -15px;
            background: $color-main;
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: lowercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-main;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 75px;
            margin: 0;
            padding: 0;
            font-size: 1.375rem;
            font-weight: $font-weight;
            text-transform: uppercase; 
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

}
